.cursor-trail {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: black;
    border-radius: 50%;
    z-index: 9999;
    pointer-events: none;
    animation: trail 1s ease-out forwards;
  }
  
  @keyframes trail {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(1);
    }
  }
  .cursor-trail {
    position: fixed;
    top: 0;
    left: 0;
    width: 10px;
    height: 10px;
    background-color: rgb(255, 245, 140);;
    border-radius: 50%;
    z-index: 9999;
    pointer-events: none;
    animation: trail 1s ease-out forwards;
  }
  
  @keyframes trail {
    from {
      opacity: 1;
      transform: scale(0);
    }
    to {
      opacity: 0;
      transform: scale(1);
    }
  }
    