.propic {
  border-radius: 50%;
  width: 25%;
  height: 25%;
  border: 3px solid rgb(244, 241, 234);
  z-index: 1;
}

body {
  background-color: rgb(6, 11, 46);
}

.particles-bg {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.Heart {
  Animation: Heart 1.6s Linear Infinite;
  transform-origin: center;
  z-index: 1;
}

@Keyframes Heart {
  0% {
    Transform: Scale(1.00);
    Box-Shadow: 0px 0px 100px rgb(255, 245, 140);
    border: 3px solid rgb(250, 255, 216);
  }

  80% {
    Transform: Scale(0.90);
    Box-Shadow: 0px 0px 20px rgba(255, 241, 43, 0.998);
    border: 3px solid rgb(214, 154, 57);
  }

  100% {
    Transform: Scale(0.80);
    Box-Shadow: 0px 0px 3px rgb(222, 185, 0);
    border: 4px solid rgb(64, 37, 0);
  }
}

.icons {
  transition: transform 1s;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s ease-in-out;
  z-index: 1;
}

.icons2 {
  transition: transform 1s;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 1.5s ease-in-out;
  z-index: 1;
}

.FR-bg {
  opacity: 0.4;
}

.icon {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: 0px 0px 0px rgb(222, 185, 50);
  z-index: 1;
}

.icon:hover {
  transition: 0.2s;
  box-shadow: 0px 0px 80px rgb(255, 245, 140);
}

.visibility {
  visibility: visible;
  opacity: 1;
}

.Comingsoon {
  color: rgb(243, 239, 208);
  Animation: Comingsoon 1.6s Linear Infinite;
  text-align: center;
  align-items: center;
  align-content: center;
  align-self: auto;
  font-family: 'Arvo', serif;
  font-family: 'Lato', sans-serif;
  display: inline-block;
  position: center;
}

.FR {
  width: 100px;
  height: 100px;
  margin: 20px;
  color: rgb(243, 239, 208);
  border-radius: 50%;
  Animation: Heart 1.6s Linear Infinite;
}

.container-logo{
  display: flex;
  margin-top: 75px;
  align-items: center;
  flex-direction: column;
}

.container-icons {
  margin-top: 75px;
  text-align: center;
  align-items: center;
}

.vi {
  left: 35%;
  top: 50%;
  position: absolute;
}

.hp {
  right: 35%;
  top: 50%;
  position: absolute;
}

.cv {
  right: 30%;
  top: 70%;
  position: absolute;
}

.gh {
  left: 30%;
  top: 70%;
  position: absolute;
}